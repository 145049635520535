/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_RMA_LIST = 'UPDATE_RMA_LIST';
export const UPDATE_RMA_DETAILS = 'UPDATE_RMA_DETAILS';
export const UPDATE_RMA_FORM_DATA = 'UPDATE_RMA_FORM_DATA';
export const UPDATE_RMA_IS_LOADING = 'UPDATE_RMA_IS_LOADING';

/** @namespace Pwa/Store/RMA/Action/updateRMAList */
export const updateRMAList = (rmaList) => ({
    type: UPDATE_RMA_LIST,
    rmaList
});

/** @namespace Pwa/Store/RMA/Action/updateRMADetails */
export const updateRMADetails = (rmaDetail) => ({
    type: UPDATE_RMA_DETAILS,
    rmaDetail
});

/** @namespace Pwa/Store/RMA/Action/updateRMAFormData */
export const updateRMAFormData = (formData) => ({
    type: UPDATE_RMA_FORM_DATA,
    formData
});

/** @namespace Pwa/Store/RMA/Action/updateRMAIsLoading */
export const updateRMAIsLoading = (isLoading) => ({
    type: UPDATE_RMA_IS_LOADING,
    isLoading
});
